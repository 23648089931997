import React from "react";
import cx from "classnames";
import { ModuleWrapper } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";

const TextContent = ({ config, text, centerText, layout }) => {
  return (
    <ModuleWrapper {...config} className="grid grid-cols-14">
      <PortableTextBlock
        text={text}
        className={cx("col-start-2 col-end-14", {
          "text-center md:col-start-3 md:col-end-13": centerText,
          "md:col-end-9": !centerText && layout === "left",
          "md:col-end-7": !centerText && layout === "drop-left",
          "md:col-start-8": !centerText && layout === "right",
          "md:col-start-9": !centerText && layout === "drop-right",
        })}
      />
    </ModuleWrapper>
  );
};

export default TextContent;
